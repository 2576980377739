import axios from 'axios';

export const getSingleProject = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/project/${id}`);
        return response;
    } catch(error) {
        console.error(`ApiService.getProjects error: ${error}`);
        return error;
    }
}