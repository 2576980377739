import React from 'react';
import FilterMenu from '../../components/filterMenu/FilterMenu';
import ProjectWrapper from '../../components/projectWrapper/ProjectWrapper'

import './mainPage.css';


const MainPage = () => {
  return (
    <>
    <div className="wrapper">
    <FilterMenu />
    <ProjectWrapper/>
    </div>
    </>
  )
}

export default MainPage