import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FilteringContext } from "../../contexts/FilteringContext";
import { getTags } from "../../services/getTags";
import "./filterMenu.css";
import { ToggleFilterContext } from "../../contexts/ToggleFilterContext";

const mobileBreakPoint = 900;
const checkboxPadding = "3px 0px";
const sortOptionMarginBottom = "5px";

export default function FilterMenu() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(viewportWidth <= mobileBreakPoint);
  const [maxHeight, setMaxHeight] = useState("70vh");
  const toggleContext = useContext(ToggleFilterContext)
  const filteringContext = useContext(FilteringContext);
  const setCurrentPage = (data) => {
    filteringContext.setCurrentPage(data)
  }
  
  const [tech, setTech] = useState([]);

  useEffect(()=>{
    const fetchData = async () => {
      const response = await getTags();
        setTech(response.data.results)
    };
    fetchData();
  }, [])

  const createTechArray = () => {
    let techArray = [];
    tech.forEach(record => {
      const newObject = {};
      newObject.name = record.name;
      newObject.tag = String(record.id)
      techArray.push(newObject)
    });
    return techArray;
  }

  const techArray = createTechArray();

  useEffect(() => {
    const getElementHeightBasedOnWindowWidth = () => {
      return window.innerWidth < mobileBreakPoint ? "100vh" : "70vh";
    };
    const handleScrollAndResize = () => {
      setViewportWidth(window.innerWidth);
      setShowMenu(window.innerWidth <= mobileBreakPoint);
      const footerHeight = document.querySelector(".footer__container").offsetHeight;
      const viewportHeight = document.body.scrollHeight;
      const actualScroll = window.scrollY + window.innerHeight;
      const distanceToFooter = viewportHeight - footerHeight + 10;
      const newMaxHeight = actualScroll > distanceToFooter ? `0vh` : getElementHeightBasedOnWindowWidth();
      setMaxHeight(newMaxHeight);
    };
    window.addEventListener("scroll", handleScrollAndResize);
     window.addEventListener("resize", handleScrollAndResize);
    return () => {
      window.removeEventListener("scroll", handleScrollAndResize);
      window.removeEventListener("resize", handleScrollAndResize);
    };
  }, []);

  useEffect(() => {
    filteringContext.whichTechnologies.length === 0 &&
    !filteringContext.isOldest &&
    !filteringContext.isMostComment &&
    !filteringContext.isMostLiked
      ? filteringContext.setIsNewest(true)
      : filteringContext.setIsNewest(false);
      // eslint-disable-next-line
  }, [filteringContext.whichTechnologies]);

  const handleOldestChange = () => {
    filteringContext.setWhichTechnologies([]);
    filteringContext.setIsNewest(false);
    filteringContext.setIsOldest(true);
    filteringContext.setIsMostComment(false);
    filteringContext.setIsMostLiked(false);
    forceScrollUp();
    setCurrentPage(1)
  };

  const handleNewestChange = () => {
    filteringContext.setWhichTechnologies([]);
    filteringContext.setIsNewest(true);
    filteringContext.setIsOldest(false);
    filteringContext.setIsMostComment(false);
    filteringContext.setIsMostLiked(false);
    forceScrollUp();
    setCurrentPage(1)
  };

  const handleMostLikedChange = () => {
    filteringContext.setWhichTechnologies([]);
    filteringContext.setIsMostLiked(true);
    filteringContext.setIsMostComment(false);
    filteringContext.setIsNewest(false);
    filteringContext.setIsOldest(false);
    forceScrollUp();
    setCurrentPage(1)
  };

  const handleMostCommentChange = () => {
    filteringContext.setWhichTechnologies([]);
    filteringContext.setIsMostComment(true);
    filteringContext.setIsMostLiked(false);
    filteringContext.setIsNewest(false);
    filteringContext.setIsOldest(false);
    forceScrollUp();
    setCurrentPage(1)
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      filteringContext.setWhichTechnologies((oldArray) => [
        ...oldArray,
        e.target.value,
      ]);
      filteringContext.setIsNewest(false);
      filteringContext.setIsOldest(false);
      filteringContext.setIsMostComment(false);
      filteringContext.setIsMostLiked(false);
      forceScrollUp();
      setCurrentPage(1)
    } else {
      removeTechnology(e);
      forceScrollUp();
      setCurrentPage(1)
    }
  };
  const removeTechnology = (e) => {
    filteringContext.setWhichTechnologies([
      ...filteringContext.whichTechnologies.filter(
        (technology) => technology !== e.target.value
      ),
    ]);
  };

  const forceScrollUp = () => {
    window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
  }

  return (
    <>
      <Box
        sx={{
          maxHeight: maxHeight,
          transform:
            showMenu && !toggleContext.isOpened
              ? "translateX(-100%)"
              : "translateX(0)",
        }}
        className="filter-menu"
      >
        <Divider />
        <List>
          <Typography sx={{ marginBottom: sortOptionMarginBottom }}>
            DATA:
          </Typography>
          {
            <>
              <FormControlLabel
                sx={{ padding: checkboxPadding }}
                control={
                  <Checkbox
                    checked={filteringContext.isNewest ? true : false}
                    onChange={handleNewestChange}
                  />
                }
                label="Od najnowszych"
              />
              <FormControlLabel
                sx={{ padding: checkboxPadding }}
                control={
                  <Checkbox
                    checked={filteringContext.isOldest ? true : false}
                    onChange={handleOldestChange}
                  />
                }
                label="Od najstarszych"
              />
            </>
          }
        </List>
        <Divider />
        <List>
          <Typography sx={{ marginBottom: sortOptionMarginBottom }}>
            POPULARNOŚĆ:
          </Typography>
          {
            <>
              <FormControlLabel
                sx={{ padding: checkboxPadding }}
                control={
                  <Checkbox
                    checked={filteringContext.isMostLiked ? true : false}
                    onChange={handleMostLikedChange}
                  />
                }
                label="Najwięcej polubień"
              />
              <FormControlLabel
                sx={{ padding: checkboxPadding }}
                control={
                  <Checkbox
                    checked={filteringContext.isMostComment ? true : false}
                    onChange={handleMostCommentChange}
                  />
                }
                label="Najwięcej komentarzy"
              />
            </>
          }
        </List>
        <Divider />

        <List>
          <Typography sx={{ marginBottom: sortOptionMarginBottom }}>
            TECHNOLOGIA:
          </Typography>
          {
            <div style={{ display: "flex", flexDirection: "column" }}>
              {techArray.map((technology) => {
                return (
                  <FormControlLabel
                    key={technology.tag}
                    sx={{ padding: checkboxPadding }}
                    control={
                      <Checkbox
                        checked={
                          filteringContext.whichTechnologies.find(
                            (element) => element === technology.tag
                          )
                            ? true
                            : false
                        }
                        value={technology.tag}
                        onChange={(e) => handleCheck(e)}
                      />
                    }
                    label={technology.name}
                  />
                );
              })}
            </div>
          }
        </List>
      </Box>
    </>
  );
}
