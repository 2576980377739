import React, { useState } from "react";
import { Box } from "@mui/system";
import demoProject from "../../../assets/demoProject.jpg";
import { motion } from "framer-motion";
import { FeedbackIcon, TechButtons } from "../../reusables/exports";
import { Badge, IconButton } from '@mui/material';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { HiCodeBracketSquare } from 'react-icons/hi2'
import { PiMonitorPlayFill } from 'react-icons/pi'

const Interaction = () => {
  const [upVoteCount, setUpVoteCount] = useState(0);
  const [badgeColor, setBadgeColor] = useState("grey");

  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Box className="how-it-works">
        <div className="titlebar">
          <h1>Pełna interakcja.</h1>
        </div>
        <p>
          Do projektów masz wgląd zarówno jako użytkownik zewnętrzny, a jeżeli
          jesteś jednym z naszych uczniów - masz możliwość zalogowania się do
          platformy i oddawania głosów oraz komentowania projektów!
        </p>
        <div className="demo-project__container" style={{marginTop: "30px"}}>
          <div className="demo-project">
            <div className="info__container">
              <h1 style={{marginBottom: "15px"}}>Aplikacja do śledzenia notowań giełdowych</h1>
              <h2 style={{marginBottom: "10px", fontWeight: "normal"}}>Autor: Jan Kowalski</h2>
              <h3 style={{fontWeight: "normal", cursor: "pointer", display: "flex", gap: 5}}>
                <HiCodeBracketSquare style={{color: "#dbc112", fontSize: 20}} /> 
                  Kod: https://some.example.code</h3>
              <h3 style={{fontWeight: "normal", cursor: "pointer", display: "flex", gap: 5}}>
                <PiMonitorPlayFill style={{color: "#dbc112", fontSize: 20}} /> 
                  Live: https://some.example.code</h3>
              <div style={{margin: "20px 0"}}>
                <Badge
                badgeContent={upVoteCount}
                  color={"primary"}
                  sx={{transform: "scale(0.85)"}}
                >
                  <IconButton
                    onClick={()=>{
                      if(upVoteCount === 0){
                        setUpVoteCount(1);
                        setBadgeColor("red");

                      } else {
                        setUpVoteCount(0);
                        setBadgeColor("grey")
                      }
                    }}  
                  >
                    <FavoriteIcon sx={{color: badgeColor}} />
                  </IconButton>

                </Badge>

                <FeedbackIcon comment_count={0} />
              </div>
              <div className="tech-used">
                Tech used: <TechButtons tags={["Python", "JavaScript"]}/>
              </div>
            </div>
            <div className="img__container">
              <img
                src={demoProject}
                alt="Screen of a Card"
                className="img-demo"
              />
            </div>
            <p className="demo-project__desc">
                Ta aplikacja umożliwia użytkownikom śledzenie notowań giełdowych i analizowanie ich zmian. Do stworzenia tej aplikacji użyto języka Python oraz JavaScript. Największym wyzwaniem podczas tworzenia tej aplikacji było...
              </p>
          </div>
        </div>
      </Box>
    </motion.div>
  );
};

export default Interaction;
