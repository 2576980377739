import React, {useState, useEffect, useContext, useRef} from "react";
import { postNewComment } from "../../services/handleComment";
import AuthContext from "../../contexts/AuthProvider";
import { getComments } from "../../services/getComments";
import {
  Typography,
  Paper,
  InputBase,
  IconButton,
  Alert
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Comment from "./singleComment/SingleComment";
import "./comments.css";
import { Link } from "react-router-dom";
import CommentContext from "../../contexts/CommentProvider";
import { AlertContext } from '../../contexts/AlertProvider';

const Comments = ({project_id, projectComments, setComments}) => {
  const {auth} = useContext(AuthContext);
  const { showAlertComponent } = useContext(AlertContext);
  const {setNewCommentsNumber} = useContext(CommentContext);
  const [commentText, setCommentText] = useState('');
  const inputRef = useRef(null);
  const [isComment, setIsComment] = useState(projectComments.length === 0 ? false : true);
  const [errorMessage, setErrorMessage] = useState('');

  const getNewNumberOfComments = async () => {
    const response = await getComments(project_id)
    return response.data.count
  }

  useEffect(()=>{
    projectComments.length === 0 ? 
      setIsComment(false) : setIsComment(true)
  }, [isComment, projectComments.length])

  async function createComment(e) {
    e.preventDefault()
    try{
      if(auth){
        if(commentText === null || commentText.trim() === ""){
          setErrorMessage("Twój komentarz nie zawiera tekstu.")
          return
        } else {
          setErrorMessage("")
          const response = await postNewComment(project_id, commentText);
          await handleGetComments();
          const fetchNewNumber = await getNewNumberOfComments();
          setNewCommentsNumber(fetchNewNumber);
          setCommentText("")
          inputRef.current.value = "";
          return response;
        }
      } else {
        showAlertComponent("Zaloguj się, aby dodać swój komentarz.");
        inputRef.current.value = "";
      }
    } catch(err) {
      console.error(err)
    }
  }
  useEffect(()=>{
    if(commentText.length > 0){
      setErrorMessage("")
    }
  }, [commentText])

  async function handleGetComments () {
    try{
      const response = await getComments(project_id);
      if(response.status && response.status === 200) {
        setComments(response.data.results);
        return response
      }
    } catch(err){
      console.error(err);
    }
  }
  return (
    <>
      <section className="comments">
        <section className="existing-comments">
        <form style={{ maxWidth: "700px", marginBottom: "50px"}}>
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  
                }}
              >
                <InputBase
                  multiline 
                  inputRef={inputRef}
                  sx={{ ml: 2, width: "100%", }}
                  placeholder="Dodaj komentarz..."
                  inputProps={{ "aria-label": "add comment" }}
                  onChange={e=>{setCommentText(e.target.value)}}
                />
                <IconButton 
                  type="submit"
                  sx={{ p: "10px", justifySelf:"flex-end" }} 
                  aria-label="text"
                  onClick={createComment}
                  >
                  <PlayArrowIcon type="submit" title="Dodaj" />
                </IconButton>
              </Paper>
        </form>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <Typography sx={{ color: "#000", fontFamily: 'League Spartan, sans-serif', }} variant={"h6"} component={"h1"}>
            {isComment? "Komentarze" : 
              auth ? 
              <Alert severity="info">
                <span style={{marginRight:"5px"}}>Do tego projektu jeszcze nie dodano komentarza. Bądź pierwszy!</span>
              </Alert>
              :
              <Alert severity="info">
                <span style={{marginRight:"5px"}}>Do tego projektu jeszcze nie dodano komentarza. Bądź pierwszy!</span>
                <Link 
                className="loginLink"
                to={"/logowanie"}>Zaloguj się</Link>
              </Alert>
            }
          </Typography>
        {
          isComment && projectComments.map((comment) => {
              return (
                        <Comment 
                          key={comment.id} 
                          comment={comment}
                          setComments={setComments}
                          projectComments={projectComments}
                          isComment={isComment}
                          />

              )
            }
          )
        }


          
        </section>
      </section>
    </>
  );
};

export default Comments;
