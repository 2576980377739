import React from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ErrorAlert = ({ message }) => {
  return (
    <Alert
    sx={{margin: "100px 20px", padding: "40px 25px"}} severity="error" variant="filled">
      <Typography variant="h4" component="div">
        Uuups! 404
      </Typography>
      <Typography variant="h6" component="p">Ta strona nie istnieje, lub poszła na kawę...</Typography>
      <Typography>{message}</Typography>
      <Box mt={2}>
        <Button sx={{marginTop: "20px", }} variant="contained">
            <Link style={{textDecoration: "none"}} to="/">
                <span style={{color: "black"}}>Wracam na stronę główną!</span>
            </Link>
        </Button>
      </Box>
    </Alert>
  );
};

export default ErrorAlert;
