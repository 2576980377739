import java from "../../../assets/java.png";
import python from "../../../assets/python.png";
import javascript from "../../../assets/javascript.png";
import react from "../../../assets/react.png";
import next from "../../../assets/nextjs.svg";
import spring from "../../../assets/spring.png";
import django from "../../../assets/django.png";
import hibernate from "../../../assets/hibernate.png";
import sqlNosql from "../../../assets/database.png";

export const itemData = [
  {
    img: java,
    title: "Java",
  },
  {
    img: python,
    title: "Python",
  },
  {
    img: javascript,
    title: "JavaScript",
  },
  {
    img: spring,
    title: "Spring",
  },
  {
    img: hibernate,
    title: "Hibernate",
  },
  {
    img: django,
    title: "Django",
  },

  {
    img: sqlNosql,
    title: "SQL/NOSQL",
  },

  {
    img: react,
    title: "React.js",
  },
  {
    img: next,
    title: "Next.js",
  },
];
