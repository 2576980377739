import React, {useContext, useState, useEffect, useRef} from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FeedbackIcon, TechButtons, UpVoteIcon } from "../../reusables/exports";
import AuthContext from "../../../contexts/AuthProvider";
import "./projectCard.css";



export default function ProjectCard({ projectDetails, userVoteRecord }) {
  const {auth} = useContext(AuthContext);
  const [upVoteRecordId, setUpVoteRecordId] = useState(null);
  const [upVoteBoolean, setUpVoteBoolean] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false); 
  const targetElementRef = useRef(null);
  const {
    id,
    title,
    author,
    thumbnail,
    created,
    comment_count,
    tags,
    up_votes,
  } = projectDetails;
  const [voteAmount, setVoteAmount] = useState(up_votes);

  const forceRerender = (newValue) => {
    setUpVoteBoolean(newValue)
  }

  const handleAmountOfVotes = async (increment) => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);
    try{
      await new Promise(resolve=>setTimeout(resolve, 700));
      if (increment) {
        setVoteAmount(prevAmount => prevAmount + 1);
      } else if(increment === 0 || increment === null){
        setVoteAmount(1)
      } else {
        setVoteAmount(prevAmount => prevAmount <= 0 ? 0 : prevAmount - 1);
      }

    } catch(err){
      console.error(err)
    } finally{
      setIsUpdating(false)
    }

  }

  useEffect(()=> {
    if(auth) {
      const [objectUserVoteRecord] = userVoteRecord;
      if( objectUserVoteRecord !== undefined ) {
        setUpVoteRecordId(objectUserVoteRecord.id);
        setUpVoteBoolean(objectUserVoteRecord.vote);
      }
    }
    
  },[userVoteRecord, auth]);

  const enableScroll = () => {
    localStorage.setItem("scrollToComments", true);
  }

  const calculateTitleLength = () => {
    let finalLength = title;
    if(title.length > 24){
      finalLength = title.slice(0, 21) + "...";
    }
    return finalLength
  }
  const [titleText, setTitleText] = useState(calculateTitleLength);

const animate = (action) => {
    const originalText = titleText || "";
    const totalFrames = title.length < 35 ? 25 : 35;

    let frame = 0;

    const animationStep = () => {
      frame++;
      const progress = Math.min(frame / totalFrames, 1);
      const currentLength = Math.floor(
        originalText.length + (title.length - originalText.length) * progress
      );
      setTitleText(title.slice(0, currentLength));

      if (frame < totalFrames) {
        requestAnimationFrame(animationStep);
      } else {
        if (action === "leave") {
          setTitleText(calculateTitleLength);
        }
      }
    };

    requestAnimationFrame(animationStep);
  };

  return (
    <motion.div
      whileHover={{
        transition: { duration: 2 },
      }}
      className="card__container"
    >
      <Card
      onTouchStart={()=>{
        // handle mobile
        if(title.length > 24) {
          animate("hover");
          }
        }
      }
      onMouseEnter={()=>{
        //handle desktop
        if(title.length > 24) {
          animate("hover");
          }
        }
      }
      onMouseLeave={()=>{
        //handle desktop
          animate("leave");
        }
      }
      onTouchEnd={()=>{
        //handle mobile
          animate("leave");
      }}

        ref={targetElementRef}
        className="card__content"
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#EEEEEE",
          maxWidth: 300,
          minWidth: 250,
          border: "2px solid #eee",
          transition: "500ms",
        }}
      >
        <CardActions sx={{ 
          padding: "10px",
          bgcolor:"rgba(255,255,255,1)", 
          position: "absolute", 
          top: "165px", 
          right: "0", 
          borderRadius: "45px 0 0 45px",
          borderLeft: "2px solid #eee",
          borderBottom: "2px solid #eee",
          borderTop: "2px solid #eee",
          gap: "10px",
           }}>
          <UpVoteIcon 
            up_votes={voteAmount} 
            project_id={id} 
            record_id={auth ? upVoteRecordId: null} 
            voteBoolean={auth ? upVoteBoolean : null}
            forcingRerender={forceRerender}
            handleAmountOfVotes={handleAmountOfVotes}
            isUpdating={isUpdating}
            setUpVoteRecordId={setUpVoteRecordId}
            setUpVoteBoolean={setUpVoteBoolean}
          />
          <Link onClick={enableScroll} to={`/projekt/${id}`}>
          <FeedbackIcon comment_count={comment_count} />
          </Link>
        </CardActions>
        <Link to={`/projekt/${id}`}>
          <CardMedia 
            component="img"
            height="194"
            image={thumbnail}
            alt="Paella dish"
          />
        </Link>
        
        <CardContent>
          <Typography
            sx={{ marginBottom: "20px", marginTop: "20px" }}
            variant="h6"
            color="text.secondary"
          >
            <Link
              style={{ 
                textDecoration: "none", 
                color: "#181819", 
                letterSpacing: "0.1px", 
                fontSize: "23px", 
                lineHeight: "1",
                fontFamily: 'League Spartan, sans-serif'
              }}
              to={`/projekt/${id}`}
            >
              {titleText}
            </Link>
          </Typography>
          <Typography sx={{
              fontSize: "14px",
              fontFamily: 'League Spartan, sans-serif',
             }} color="text.secondary">
            Autor: {author}
          </Typography>
        </CardContent>
        <CardContent
          sx={{
            justifySelf: "end",
            padding: "16px",
            display: "flex",
            gap: "4px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <TechButtons tags={tags.slice(0, 3)} />
          {
            tags.length > 3 ?
            <Link style={{textDecoration: "none", color: "black"}} to={`/projekt/${id}`}>
              <TechButtons
                style={["pointer", "none"]}
                tags={["..."]}/>
              </Link>
            :
            null
          }
        </CardContent>
        <Typography
          variant="body2"
          sx={{ 
            fontSize: "11px",
           padding: "0 16px 5px",
          fontFamily: 'League Spartan, sans-serif',
         }}
        >
          {`Data dodania: ${String(created).slice(0, 11).replaceAll("-", ".")}`}
        </Typography>
      </Card>
    </motion.div>
  );
}
