import { createContext, useState } from "react";

export const ToggleFilterContext = createContext(null);

export const ToggleFilterProvider = ({children}) => {
    const [isOpened, setIsOpened] = useState(false)
    return (
        <ToggleFilterContext.Provider value={{isOpened, setIsOpened}}>
            {children}
        </ToggleFilterContext.Provider>
    )
}
