import { useRef, useState } from 'react';
import { Link } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../../assets/logoIcon.webp";
import { motion } from "framer-motion";

const JoinUs = () => {
  const logoRef = useRef(null);
  const [logoOpacity, setLogoOpacity] = useState("0.5");
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Box className="how-it-works joinUs">
        <div className="titlebar">
          <h1>Dołącz do nas!</h1>
        </div>
        <div className="content">
          <div className="content__text">
            <p style={{lineHeight: "1.5"}}>
              Jeżeli podoba Ci się to, co robimy i również chciał_byś rozwinąć
              się pod naszymi skrzydłami, to zapoznaj się z naszym modelem pracy
              i śmiało wyślij zgłoszenie przez stronę{" "}
              <Link
                onMouseEnter={()=>{
                  setLogoOpacity("1");
                }}
                onMouseLeave={()=>{setLogoOpacity("0.5")}}
                className="test"
                href="https://www.devs-mentoring.pl/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                devs-mentoring.pl
              </Link>
              .
            </p>
          </div>
          <div className="content__logo">
              <img
                ref={logoRef}
                style={{filter: `opacity(${logoOpacity})`}}
                src={logo}
                className="logo__link"
                alt="Logo of devs-mentoring"
              />
          </div>
        </div>
      </Box>
    </motion.div>
  );
};

export default JoinUs;
