import React, { createContext, useState } from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState(null);

  const showAlertComponent = (text) => {
    setShowAlert(true);
    setAlertText(text);
  };

  const hideAlertComponent = () => {
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider value={{ showAlertComponent, hideAlertComponent, showAlert, alertText }}>
      {children}
    </AlertContext.Provider>
  );
};
