import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const fetchedUserId = localStorage.getItem("userId")
    const [auth, setAuth] = useState( 
        fetchedUserId ? fetchedUserId : false
    );
    return(
        <AuthContext.Provider value={{ auth, setAuth }}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthContext;