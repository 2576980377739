import React, { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import './cookieBanner.css'
import { useCookies } from "react-cookie";

const CookieBanner = () => {

  const [cookies, setCookie] = useCookies(['policy_accepted']);
  const [bannerVisible, setBannerVisible] = useState(!cookies.policy_accepted);

  useEffect(() => {
    setBannerVisible(!cookies.policy_accepted);
  }, [cookies.policy_accepted]);

  const handleAccept = () => {
    document.querySelector(".cookieBanner").style.bottom = "-200px";
    setTimeout(()=>{
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 6);
      setCookie('policy_accepted', true, { expires: expirationDate });
    }, 1000)
  };

    return (
        <>
            {
                bannerVisible && 
                <div className="cookieBanner">
                  <CookieOutlinedIcon sx={{fontSize: "50px"}}  />
                  <p className="cookieBanner__text">
                      Chcielibyśmy poinformować Cię, że używamy plików cookies jedynie w celu zapewnienia poprawnego logowania. Nie korzystamy z plików cookies do śledzenia Twojej aktywności ani do jakiejkolwiek formy monitorowania.
                  </p>
                  <CancelIcon color="primary" sx={{'&:hover': {cursor: "pointer"}}} onClick={handleAccept} />
                </div>

            }
        </>
    )
}

export default CookieBanner;