import React, { useState } from "react";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { motion } from "framer-motion";
import "./howItWorksPage.css";
import * as Components from "../../components/howItWorksComponents/export";

const HowItWorksPage = () => {
  const [active, setActive] = useState("About");

  const handleButtonClick = (section) => {
    setActive(section);
  };

  const renderButton = (section, label) => (
    <motion.a whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
      <Button
        onClick={() => handleButtonClick(section)}
        className={`how-it-works__button ${active === section ? "active" : ""}`}
        sx={{ fontFamily: "League Spartan" }}
      >
        {label}
      </Button>
    </motion.a>
  );

  return (
    <Container className="how-it-works__container">
      <div className="how-it-works__buttons">
        {renderButton("About", "1. Dlaczego?")}
        {renderButton("Technologies", "2. Technologie")}
        {renderButton("Interaction", "3. Interakcja")}
        {renderButton("JoinUs", "4. Dołącz do nas!")}
      </div>
      <div className="how-it-works__content">
        {active === "About" && <Components.About />}
        {active === "Technologies" && <Components.Technologies />}
        {active === "Interaction" && <Components.Interaction />}
        {active === "JoinUs" && <Components.JoinUs />}
      </div>
    </Container>
  );
};

export default HowItWorksPage;
