import axios from 'axios';

export const getComments = async (project_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/comment/?project=${project_id}`);
        return response;
    } catch(error) {
        console.error(`Comments get axios error: ${error}`);
        return error;
    }
}