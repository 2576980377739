import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingComponent from '../loadingComponent/LoadingComponent'

const LoggedInComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);
  const { usedLogin } = location.state;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRedirect(true);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(()=>{
    if(redirect) {
      navigate("/")
    }
  },[redirect, navigate])

  return (
    <div
    style={{
      margin: "50px auto",
      padding: "2px 10px",
    }}>
      {
        usedLogin ? 
        <>
        <h1>Witaj {usedLogin}</h1>
        <h2>Logowanie poprawne</h2>
        </> 
        : 
        <>
        <h2>Logowanie poprawne.</h2>
        </>
      }
      <p>Za kilka chwil nastąpi przekierowanie na stronę główną...</p>
      <LoadingComponent/>
    </div>
  );
};

export default LoggedInComponent;
