import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import LoadingComponent from '../loadingComponent/LoadingComponent';

const PwdChanged = () => {
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        setTimeout(()=>{setLoading(false)}, 1000);
    }, [])

    if(loading){
        return(
            <LoadingComponent />
        )
    } else {
    return (
        <div
        className='logout'
        style={{
        margin: "100px 30px",
        }}>
            <>
                <h1>Poprawna zmiana hasła.</h1>
                <h2>Zaloguj się ponownie, używając nowego hasła.</h2>
            </>
            <div style={{textAlign: "center", marginTop: "25px"}}>
            <Link to={"/logowanie"}>
                <Button variant='contained' >
                    Zaloguj się ponownie
                </Button>
            </Link>
            </div>
        </div>
    )};
};

export default PwdChanged;
