import axios from 'axios';
import { getCookie } from './getCookie';

export const postUpVote = async (projectId) => {
    try {
        const csrfToken = getCookie("csrftoken");
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/votesrecords/`, {
            "project": projectId,
            "vote": true
            },
            {
                withCredentials: "true",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                mode: "same-origin"
            }
        );
        return response;
    } catch(error) {
        console.error(`Post upVote error: ${error}`);
        return error;
    }
}

export const patchUpVote = async (voteId, projectId, voteBoolean) => {
    
    try {
        const csrfToken = getCookie("csrftoken");
        const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/votesrecords/${voteId}/`, {
            "project": projectId,
            "vote": !voteBoolean,
        },
        {
            withCredentials: "true",
            headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                mode: "same-origin"
        });
        return response;
    } catch(err){
        console.error(err)
    }
}