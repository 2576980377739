import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material';
import { AuthProvider } from './contexts/AuthProvider';
import { CommentProvider } from './contexts/CommentProvider';
import { AlertProvider } from './contexts/AlertProvider';
import { FilteringProvider } from "./contexts/FilteringContext";

const theme = createTheme({
  palette: {
    mode: "light",
    primary:{
    main: '#DBC111',
    light: '#1d32f2',
    dark: '#a99511',
    contrastText: '#181819',
  },
  text:{
    primary: '#181819',
    secondary: '#181819',
  },
  background: {
    paper: '#F9F9F9',
    default: '#C8C9C7',
  },
  action:{
    selected: "#000000",
  }
}
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <CommentProvider>
        <FilteringProvider>
        <AlertProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </BrowserRouter>
        </AlertProvider>
        </FilteringProvider>
      </CommentProvider>
    </AuthProvider>
  </React.StrictMode>
);
