import React from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import logoIconFooter from '../../assets/logoIcon-footer.webp'

import "./footer.css";

const Footer = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      className="footer__container"
    >
      <Grid item xs={12} md={3} className="info__container">
        <Box>
          <Box className="logo__container">
            <img
              src={logoIconFooter}
              alt="logo of Devs-Mentoring"
              className="logo-img"
            />
          </Box>
          <Box className="motto__container">
            <p>Mentorujemy i szkolimy zdolnych programistów!</p>
          </Box>
          <Box className="identify__container">
            <p>NIP: 8311641047</p>
            <p>REGON: 388216044</p>
          </Box>
        </Box>

        <Box className="copyrights__container --from-900">
          <p>Copyright © 2023 Devs Mentoring</p>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box className="technology__container">
          <h2 className="section_title">OBSZARY MENTORINGU</h2>
          <p>Frontend</p>
          <p>Backend</p>
          <p>Data Science & Engineering</p>
        </Box>
        <Box className="technology__container">
          <h2 className="section_title">TECHNOLOGIE</h2>
          {[
            "Python",
            "JavaScript",
            "React.js",
            "Django",
            "REST API",
            "Docker",
            "SQL/noSQL",
            "Java",
            "Spring",
          ].map((technology) => {
            return <p key={technology}>{technology}</p>;
          })}
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box>
          <h2 className="section_title">ODWIEDŹ NAS</h2>
          <Box className="links_container">
            <Link className="link" href="https://www.linkedin.com/company/devs-hunting/">
              Linkedin
            </Link>
            <Link className="link" href="https://www.facebook.com/devs.mentoring">Facebook</Link>
          </Box>
        </Box>
        <Box>
          <h2 className="section_title">KONTAKT</h2>
          <Link className="link" href="mailto:contact@devs-mentoring.com">
            contact@devs-mentoring.com
          </Link>
        </Box>
        <Box sx={{marginTop: "40px"}} className="copyrights__container --to-900">
          <p>Copyright © 2023 Devs Mentoring</p>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
