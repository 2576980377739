import axios from 'axios';

export const getNewestProjects = async (page) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/project/?ordering=-created&page=${page}&page_size=10`);
        return response;
    } catch(error) {
        console.error(`Api request getProjects error: ${error}`);
        return error;
    }
}