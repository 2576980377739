import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "./logo/Logo";
import TuneIcon from "@mui/icons-material/Tune";
import { Link } from "react-router-dom";
import { Link as MUILink } from "@mui/material";
import { ToggleFilterContext } from "../../contexts/ToggleFilterContext";
import AuthContext from "../../contexts/AuthProvider";
import { logout } from "../../services/logout";

const pages = [
  "Home",
  "Jak to działa?"
];

function Navigation() {
  const {auth, setAuth} = useContext(AuthContext);
  const navigationRoutingPaths = {
    "Home": "/",
    "Jak to działa?": "/jak-to-dziala"
  };
  const settingsRoutingPaths = {
    Zaloguj: "/logowanie",
    Wyloguj: "/wylogowanie",
    "Zmień hasło": "/zmiana-hasla"
  };
  const [settings, setSettings] = useState(auth ? ["Zmień hasło", "Wyloguj"] : ["Zaloguj"]);
  useEffect(()=>{
    auth ?
    setSettings(["Zmień hasło", "Wyloguj"]) 
    : setSettings(["Zaloguj"])
  }, [auth])
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const toggleContext = useContext(ToggleFilterContext);
  const { pathname } = useLocation();
  const isMainPage = pathname === '/'

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  async function actLogout() {  
    if(auth){
      
      setAuth(false);
      localStorage.removeItem("userId")
      try{
        await logout();
      } catch(err){
        console.error(err);
      }
    } else {
      return
    }
  }

  const toggleFilterMenu = () => {
    toggleContext.setIsOpened(!toggleContext.isOpened)
  };
  const [navBackground, setNavBackground] = useState("transparent");
  useEffect(()=>{
    const handleScroll = () => {
      window.scrollY > 20 ?
        setNavBackground("#ffffff")
        :
        setNavBackground("transparent")
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])
  
  return (
    <>
      <AppBar sx={{bgcolor: navBackground, padding: "10px 0" }} position="fixed">
          <Container maxWidth="l">
            <Toolbar disableGutters>
              <Logo sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
              <Box sx={{marginLeft: "5px", flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (        
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Link
                      key={page}
                      to={navigationRoutingPaths[page]}
                      style={{textDecoration:"none", color: "black"}}
                      >
                        <Typography>{page}</Typography>
                      </Link>
                    </MenuItem>
                  ))}
                  <MenuItem key="mentoring" onClick={handleCloseNavMenu}>
                      <MUILink
                      href="https://www.devs-mentoring.pl"
                      target="_blank"
                      rel="noopener"
                      key="mentoring"
                      style={{textDecoration:"none", color: "inherit"}}
                      >
                        Mentoring
                      </MUILink>
                    </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Link
                    key={page}
                    to={navigationRoutingPaths[page]}
                    className="onHover"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: page==="Home" && window.location.pathname==="/" ? "#DBC111" : page==="Jak to działa?" && window.location.pathname==="/jak-to-dziala" ? "#DBC111" : "black",
                        display: "block",
                        textDecoration: "none",
                        "&:hover": {
                          color: "#DBC111",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  </Link>
                ))}
                <MUILink
                      href="https://www.devs-mentoring.pl"
                      target="_blank"
                      rel="noopener"
                      key="mentoring"
                      style={{textDecoration:"none", color: "inherit"}}
                      >
                        <Button
                      key="Mentoring"
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        textDecoration: "none",
                        "&:hover": {
                          color: "#DBC111",
                        },
                      }}
                    >
                      Mentoring
                    </Button>
                      </MUILink>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                  {isMainPage &&  
                    <Tooltip title={toggleContext.isOpened ? "Zamknij sortowanie" : "Sortuj"}>  
                      <IconButton
                      sx={{ 
                        flexGrow: 1, 
                        backgroundColor: toggleContext.isOpened ? "#f5f5f5" : "transparent",
                        marginRight: "5px", 
                        display: { md: "none" } }}
                      size="large"
                      aria-label="filter menu switcher"
                      color="inherit"
                      onClick={toggleFilterMenu}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  }
                <Tooltip 
                  title={auth ? "Jesteś zalogowany" : "Zaloguj się"}
                  >
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src=""
                      sx={{ backgroundColor: auth ? "green" : "#354438" }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {
                  settings.map((setting) => (
                    <Link
                      key={setting}
                      onClick={
                        setting === "Wyloguj" ?
                          actLogout
                          :
                          null
                      }
                      to={settingsRoutingPaths[setting]}
                      style={{ textDecoration: "none", color: "#181819" }}
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                          <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    </Link>))
                  }
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
    </>
  );
}
export default Navigation;
