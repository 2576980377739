import React, { useState, useEffect, useContext } from 'react';
import { getUsersComments, deleteComment, patchComment } from '../../../services/handleComment';
import AuthContext from "../../../contexts/AuthProvider";
import { getComments } from '../../../services/getComments';
import {
  Typography,
  Paper,
  IconButton,
  TextField,
  Button
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CommentContext from '../../../contexts/CommentProvider';
import "../comments.css"

const Comment = ({comment, setComments, projectComments, isComment}) => {
  const {
    id,
    author,
    project,
    text,
    created
  } = comment;
  const {auth} = useContext(AuthContext);
  const {setNewCommentsNumber} = useContext(CommentContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [editedComment, setEditedComment] = useState(text);
  const [commentDate, setCommentDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(()=> {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const getArrayOfUsersComments = async () => {
        try {
          const response = await getUsersComments(project, userId);
          return response.data.results
        } catch (error) {
          console.error(error);
        }
      };
      getArrayOfUsersComments().then((comment) => {
        if(comment.some(item => item.id === id)){
          setIsAllowed(true);
        }
      })
    }
    //eslint-disable-next-line
  }, [projectComments, isAllowed, isEditing, isComment]);

  useEffect(() => {
  const calculateDate = async () => {
    const timestamp = await created;
    const [date, time] = timestamp.split(' ');
    const [year, month, day] = date.split('-').map(Number);
    const [hour, minute, second] = time.split(':').map(Number);
    const adjustHour = hour + 2; //needed because backend date of added comment is set to another timezone I believe

    const commentDate = new Date(year, month - 1, day, adjustHour, minute, second);
    setCommentDate(commentDate);
  };
  calculateDate();
}, [created]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    setEditedComment(text)
  }

  const handleSave = () => {
    if(editedComment.length > 0) {
      const newCommentText = editedComment;
      handlePatchComment(newCommentText);
      setIsEditing(false);
    } else {
      setErrorMessage(true)
    }
  };

  const handlePatchComment = async (newText) => {
    try{
      await patchComment(id, project, newText);
      await handleGetComments();
    } catch (err) {
      console.error(err)
    }
  }

    const getNewNumberOfComments = async () => {
    const response = await getComments(project)
    return response.data.count
  }

  const handleDeleteComment = async () => {
    try{
      await deleteComment(id);
      await handleGetComments();
      const fetchNewNumber = await getNewNumberOfComments();
      setNewCommentsNumber(fetchNewNumber);
    } catch(err){
      console.error(err)
    }
  }

  const handleCommentChange = (event) => {
    setEditedComment(event.target.value);
    setErrorMessage("");
  };

  async function handleGetComments () {
      try{
        const response = await getComments(project);
        if(response.status && response.status === 200) {
          setComments(response.data.results);
          return response
        }
      } catch(err){
        console.error(err);
      }
    };

  function handleDate() {
    if (!commentDate) {
      return null;
    }
    const now = new Date();
    const timeDifference = now - commentDate;
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
    let info;

    if (years > 0) {
      info = `${years} ${getPolishEnding(years, ['rok', 'lata', 'lat'])} temu`;
    } else if (months > 0) {
      info = `${months} ${getPolishEnding(months, ['miesiąc', 'miesiące', 'miesięcy'])} temu`;
    } else if (days > 0) {
      info = `${days} ${getPolishEnding(days, ['dzień', 'dni', 'dni'])} temu`;
    } else if (hours > 0) {
      info = `${hours} ${getPolishEnding(hours, ['godzina', 'godziny', 'godzin'])} temu`;
    } else if (minutes > 0) {
      info = `${minutes} ${getPolishEnding(minutes, ['minuta', 'minuty', 'minut'])} temu`;
    } else {
      info = 'Przed chwilą';
    }

    return <div>{info}</div>;
  }

  function getPolishEnding(count, endings) {
    let ending;
    if (count === 1) {
      ending = endings[0];
    } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
      ending = endings[1];
    } else {
      ending = endings[2];
    }
    return ending;
  }

  return (
        <div className="existing-comment-wrapper">
                <Paper
                  component="div"
                  sx={{
                    height: "auto",
                    p: "20px 40px",
                    display: "grid",
                    alignItems: "center",
                    width: "clamp(200px, 55vw, 600px)",
                    '@media (max-width: 900px)': {
                      padding: '10px 20px',
                    },
                    '@media (max-width: 650px)': {
                      padding: '5px 10px',
                      width: "clamp(250px, 70vw, 600px)",
                    },
                    '@media (max-width: 470px)': {
                      padding: '2px 5px',
                      width: "clamp(250px, 70vw, 600px)",
                    },
                  }}
                >
                  <div className="comment-author-details">
                    <Typography 
                    sx={{
                      '@media (max-width: 470px)': {
                        fontSize: ".7em",
                    },
                    }}
                    variant="body2" component="p">
                      {`@${author}`}
                    </Typography>
                    <Typography 
                    sx={{
                      color: "grey", opacity: "50%", fontSize: "12px",
                      '@media (max-width: 470px)': {
                        fontSize: ".7em",
                    },
                    }} 
                    variant="body2" component="span">
                      {handleDate()}
                    </Typography>
                  </div>
                  
              {isEditing && isAllowed ? (
                <TextField
                  sx={{fontFamily: 'League Spartan, sans-serif',}}
                  className='comment-text'
                  multiline
                  value={editedComment}
                  onChange={handleCommentChange}
                  variant="outlined"
                />
              ) : (
                <Typography
                            className="comment-text"
                            variant="body1"
                            component="span"
                            sx={{fontFamily: 'League Spartan, sans-serif',}}
                          >
                            {text}
                          </Typography>
              )}
              {isEditing && isAllowed ? (
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "20px"}}>
                  {errorMessage && <p style={{ color: 'red', width: "100%", textAlign: "center", marginBottom: "0px" }}>Twój komentarz nie zawiera tekstu.</p>}
                  <Button
                    variant="contained"
                    sx={{
                      width: "clamp(90px, 10vw, 150px)",
                      margin: "5px"
                  }}
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                  >
                    Zapisz
                  </Button>
                  <Button onClick={()=>{
                    handleCloseEdit();
                    setErrorMessage("");
                  }}>
                    Anuluj
                  </Button>
                </div>
              ) : (
                <>
                    {auth && isAllowed ? 
                              <div className="loggedInAuthorOfComment">
                                <Tooltip title="Edytuj">
                                  <IconButton onClick={handleEdit}>
                                    <EditIcon  fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Usuń">
                                  <IconButton onClick={handleDeleteComment}>
                                    <DeleteForeverIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              :
                              null
                    }
                </>        
              )}
                </Paper>
              </div>
  );
};

export default Comment;
