import React from 'react';
import "./logo.css";
import LogoIcon from '../../../assets/logoIcon.webp'
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <>
      <Link to="/">
        <div className="logo" >
          <img src={LogoIcon} alt="Logo" />
        </div>
      </Link>
    </>
  )
}

export default Logo