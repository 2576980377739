import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Comments from "../../components/comments/Comments";
import ProjectDetails from "../../components/projectDetails/ProjectDetails";
import { getComments } from "../../services/getComments";
import { fetchVotesRecords } from "../../services/fetchVotesRecords";
import { getSingleProject } from "../../services/getSingleProject";
import LoadingComponent from "../../components/loadingComponent/LoadingComponent";
import AuthContext from "../../contexts/AuthProvider";

const SingleProjectPage = () => {
  const {auth} = useContext(AuthContext);
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [comments, setComments] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [filteredRecord, setFilteredRecord] = useState(null);
  const userId = localStorage.getItem("userId");
  
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
    async function handleGetComments () {
      const response = await getComments(id);
      if(response.status && response.status === 200) {
        setComments(response.data.results);
        return response
      } else {
        setError(response);
        console.error(error);
      }
    }
    handleGetComments();
    const fetchData = async () => {
      try{
        const response = await getSingleProject(id);
        if (response.status && response.status === 200) {
          setProject(response.data);
        } else {
          setError(response);
          console.error(error);
          navigate("*");
        };
        if(userId && auth){
          const getArrayOfVotes = async () => {
            try{
              const response = await fetchVotesRecords();
              return response.data.results;
            }catch(err){
              console.error(err)
            }
          }
          getArrayOfVotes().then((allVotesArray)=>{
              const filteredUserVoteForProject = allVotesArray.filter(record => Number(id) === Number(record.project) && Number(userId) === Number(record.person))
              setFilteredRecord(filteredUserVoteForProject);
          })
        }
      } catch(error){
        console.error(error)
      }
    }
      fetchData();
      //eslint-disable-next-line
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <div style={{ minHeight: "100vh" }}>
      {project && (
        <>
          <ProjectDetails 
            key={project.id} 
            projectDetails={project} 
            loading={loading}
            userVoteRecord={
              auth ? filteredRecord : false
            }
          />
          <Comments 
            key={`${project.id}-comments`} 
            project_id={id} 
            projectComments={comments}
            setComments={setComments}
            />
        </>
      )}
    </div>
  );
};

export default SingleProjectPage;
