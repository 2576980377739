import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import { login } from '../../services/login';
import './login.css';
import AuthContext from '../../contexts/AuthProvider';

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [password, setPassword] = useState('');
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validation = (e, element) => {
    const input = e.target.value;
    if (input === '') {
      element(true);
    } else {
      element(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(loginValue, password);
      if (response.status === 200) {
        const fetchUserId = response.data.user_id;
        localStorage.setItem("userId", fetchUserId);
        setAuth(fetchUserId);
        const usedLogin = JSON.parse(response.config.data).email;
        const state = {usedLogin: usedLogin}
        navigate('/poprawne-logowanie', { state });
      }
    } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      const statusCode = parseInt(error.message);
      if (statusCode === 400) {
        setErrorMessage('Niepoprawny login lub hasło');
      } else if (statusCode === 401) {
        setErrorMessage('Błąd logowania.');
      } else if (statusCode === 403) {
        setErrorMessage('Odmowa dostępu.')
      } else {
        setErrorMessage('Login failed');
      }
    } else {
      setErrorMessage('Login failed');
    }
  }
}

  return (
    <>
      <form className='login' onSubmit={handleSubmit}>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          validate='true'
          autoComplete='off'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <TextField
              onChange={(e) => {
                setLoginValue(e.target.value);
                validation(e, setErrorLogin);
              }}
              id='outlined'
              label='Login'
              error={errorLogin}
            />
            <FormControl
              onChange={(e) => {
                validation(e, setErrorPassword);
              }}
              error={errorPassword}
              sx={{ m: 1, width: '25ch' }}
              variant='outlined'
            >
              <InputLabel
                htmlFor='outlined-adornment-password'
                onChange={(e) => {
                  validation(e, setErrorPassword);
                }}
                error={errorPassword}
              >
                Password
              </InputLabel>
              <OutlinedInput
                onChange={(e) => {
                  setPassword(e.target.value);
                  validation(e, setErrorPassword);
                }}
                required
                error={errorPassword}
                id='outlined-adornment-password'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
              />
            </FormControl>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <Button type='submit' sx={{ m: 1, width: '25ch' }} variant='contained'>
              Zaloguj
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};

export default Login;
