import React, { useContext, useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { AlertContext } from '../../contexts/AlertProvider';

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

const Notification = () => {
  const { showAlert, hideAlertComponent, showAlertComponent, alertText } = useContext(AlertContext);
  const [open, setOpen] = useState(showAlert);
  const [timer, setTimer] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setOpen(false);
      setTimer(null)
      return;
    }
    setOpen(false)
  };

  useEffect(() => {
    if(timer){
      clearTimeout(timer)
    }
    if (showAlertComponent) {
      setOpen(true);
      const newTimer = setTimeout(() => {
        hideAlertComponent();
        setOpen(false);
        setTimer(newTimer)
      }, 2500);
      return () => clearTimeout(newTimer);
    }
  }, [hideAlertComponent, showAlertComponent, timer]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition} 
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >  
        <Alert sx={{padding: "10px 20px", fontSize: "15px"}} onClose={handleClose} severity="warning">
         {alertText}
       </Alert>
    </Snackbar>
  );
};

export default Notification;