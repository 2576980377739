import React from 'react';
import { Box } from "@mui/material";
import "./techButtons.css";

const TechButtons = ({tags, style}) => {
  return (
    tags.map((tag, index) => {
            return (
              <Box
              sx={{
                boxShadow: "0",
                cursor: style ? `${style[0]}` : "default",
                bgcolor: "#dbc111",
                padding: "5px 14px",
                borderRadius: "75px",
                fontSize: "15px",
                letterSpacing: "-1px",
                textAlign: "center"
              }}
                key={index}
                className="project-card-tech-button"
                variant="contained"
              >
                {tag}
              </Box>
            );
          })
  )
}

export default TechButtons