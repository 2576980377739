import { createContext, useState} from "react"

export const FilteringContext = createContext(undefined)

export const FilteringProvider = ({children}) => {
    const [isNewest, setIsNewest] = useState(true)
    const [isOldest, setIsOldest] = useState(false)
    const [isMostLiked, setIsMostLiked] = useState(false)
    const [isMostComment, setIsMostComment] = useState(false)
    const [whichTechnologies, setWhichTechnologies] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <FilteringContext.Provider value={{currentPage, setCurrentPage, isNewest, setIsNewest, isOldest, setIsOldest, isMostLiked, setIsMostLiked, isMostComment, setIsMostComment, whichTechnologies, setWhichTechnologies, 
}}>
            {children}
        </FilteringContext.Provider>
    )
}