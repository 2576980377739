import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import "./logout.css";

const Logout = () => {
  return (
    <>
    <div className="logout">
      <Typography>Wylogowano poprawnie.</Typography>
      <Link to={"/logowanie"}>
        <Button variant="contained">
          Zaloguj się ponownie
        </Button>
      </Link>
      <Link to={"/"}>
        <Button variant="contained" sx={{backgroundColor: "#C8C9C7"}}>
          Powrót do strony głównej
        </Button>
      </Link>
    </div>
    </>
  )
}

export default Logout