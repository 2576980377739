import axios from 'axios';

export const logout = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/logout/`,
        {withCredentials: true})
        return response;
    } catch(error) {
        console.error(`Get logout request error: ${error}`);
        return error;
    }
}