import { createContext, useState } from "react";

const CommentContext = createContext({});

export const CommentProvider = ({children}) => {
    const [newCommentsNumber, setNewCommentsNumber] = useState(null);
    return(
        <CommentContext.Provider value={{ newCommentsNumber, setNewCommentsNumber }}>
            { children }
        </CommentContext.Provider>
    )
}

export default CommentContext;