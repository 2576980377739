import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {motion} from 'framer-motion';

import { itemData } from "./data";

const Technologies = () => {
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Box className="how-it-works">
        <div className="titlebar">
          <h1>Po co?</h1>
        </div>
        <p>
          Stworzyliśmy platformę, na której możemy dzielić się z innymi owocami
          pracy z kursantami w postaci indywidualnie tworzonych projektów do
          portfolio. Wiemy, że nic tak nie motywuje, jak pozytywne słowo od
          kolegi, któremu spodobał się projekt, który zrealizowałeś!
        </p>
        <p style={{ marginBottom: "2rem" }}>
          Tech-stack, z którego szkolimy i budujemy projekty to m.in
        </p>
        <Grid container spacing={1}>
          {itemData.map((item) => {
            return (
              <Grid item xs={6} md={3} key={item.title} className="technology-img">
                <div>
                  <img
                    src={item.img}
                    alt={item.title}
                    style={{ width: "90px", height: "90px" }}
                  />
                </div>
                <div style={{ marginTop: "15px", fontWeight: "600" }}>
                  {item.title}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </motion.div>
  );
};

export default Technologies;
