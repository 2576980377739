import React from 'react';
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";

const FeedbackIcon = ({comment_count}) => {
  const handleScroll = () => {
    if(document.querySelector(".comments")){
      const commentSection = document.querySelector(".comments")
      window.scrollTo({
        top: commentSection.offsetTop,
        behavior: "smooth",
      });
    }
  }
  return (
    <Badge 
      onClick={handleScroll}
      sx={{transform: "scale(0.85)"}}
      badgeContent={comment_count} 
      color={"primary"}
      >
        <IconButton
          sx={{ color: "blue !important" }}
          aria-label="share"
        >
        <CommentIcon />
      </IconButton>
    </Badge>
  )
}

export default FeedbackIcon