import axios from 'axios';

export const getProjectsByTechnologies = async (page, technologies) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/project/?page=${page}&page_size=10&tags=${technologies}`);
        return response;
    } catch(error) {
        console.error(`Api request getProjects error: ${error}`);
        return error;
    }
}