import React, { useState, useEffect, useContext } from "react";
import ProjectCard from "./projectCard/ProjectCard";
import LoadingComponent from "../loadingComponent/LoadingComponent";
import { Pagination, Stack, Alert } from '@mui/material';
import "./projectWrapper.css";
import { getOldestProjects } from "../../services/getOldestProjects";
import { getNewestProjects } from "../../services/getNewestProjects";
import { getMostCommentProjects } from "../../services/getMostCommentProjects";
import { getMostLikedProjects } from "../../services/getMostLikedProjects";
import { getProjectsByTechnologies } from "../../services/getProjectsByTechnologies";
import { motion } from "framer-motion";
import { FilteringContext } from "../../contexts/FilteringContext";
import { fetchVotesRecords } from "../../services/fetchVotesRecords";
import AuthContext from "../../contexts/AuthProvider";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};
const item = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const ProjectWrapper = () => {
  const {auth} = useContext(AuthContext);
  const [projects, setProjects] = useState(null);
  const [paginationPages, setPaginationPages] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const filteringContext = useContext(FilteringContext);
  const technologies = filteringContext.whichTechnologies.join('%2C');
  const [userVotesRecords, setUserVotesRecords] = useState(null);
  const currentPage = filteringContext.currentPage;
  const getPaginationCount = (numberOfProjects) => {
    try {
      const projectsPerRequest = 10;
      const pagesToDisplay = numberOfProjects / projectsPerRequest;
      setPaginationPages(Math.ceil(pagesToDisplay));
    } catch (error) {
      console.error(`handlePaginationCount.js error: ${error}`)
    }
  }

  const setCurrentPage = (data) => {
    filteringContext.setCurrentPage(data)
  }

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const getArrayOfVotes = async () => {
        try {
          const response = await fetchVotesRecords();
          return response.data.results;
        } catch (error) {
          console.error(error);
        }
      };
      getArrayOfVotes().then((allVotesArray) => {
        const filteredUserRecords = allVotesArray.filter((record) => Number(record.person) === Number(userId));
        setUserVotesRecords(filteredUserRecords)
      });
    }
  }, []);

  useEffect(() => {
    const handleFiltering = async () => {
      setLoading(true);

      if (filteringContext.isNewest) {
        await handleGetNewestProjects(currentPage);
      }
      if (filteringContext.isOldest) {
        await handleGetOldestProjects(currentPage);
      }
      if (filteringContext.isMostLiked) {
        await handleGetMostLikedProjects(currentPage);
      }
      if (filteringContext.isMostComment) {
        await handleGetMostCommentProjects(currentPage);
      }
      if (filteringContext.whichTechnologies.length > 0) {
        await handleGetProjectsByTechnologies(currentPage, technologies);
      }
      setTimeout(()=>setLoading(false), 400)
      
    };

    if (filteringContext !== categoryFilter) {
      setCategoryFilter(filteringContext);
    } else {
      handleFiltering();
    }
    // eslint-disable-next-line
  }, [filteringContext, currentPage, technologies, categoryFilter]);

  async function handleGetNewestProjects(currentPage) {
    const response = await getNewestProjects(currentPage);
    if (response.status && response.status === 200) {
      setProjects(response.data.results);
      getPaginationCount(response.data.count)
    } else {
      setError(response);
    }
  }

  async function handleGetOldestProjects(currentPage) {
    const response = await getOldestProjects(currentPage);
    if (response.status && response.status === 200) {
      setProjects(response.data.results);
      getPaginationCount(response.data.count)
    } else {
      setError(response);
    }
  }

  async function handleGetMostLikedProjects(currentPage) {
    const response = await getMostLikedProjects(currentPage);
    if (response.status && response.status === 200) {
      setProjects(response.data.results);
      getPaginationCount(response.data.count)
    } else {
      setError(response);
    }
  }

  async function handleGetMostCommentProjects(currentPage) {
    const response = await getMostCommentProjects(currentPage);
    if (response.status && response.status === 200) {
      setProjects(response.data.results);
      getPaginationCount(response.data.count)
    } else {
      setError(response);
    }
  }

  async function handleGetProjectsByTechnologies(currentPage, technologies) {
    const response = await getProjectsByTechnologies(currentPage, technologies);
    if (response.status && response.status === 200) {
      setProjects(response.data.results);
      getPaginationCount(response.data.count)
    } else {
      setError(response);
    }
  }

  if (loading) {
    return <LoadingComponent />;
  } else {
    
  return (
    <motion.ul
      style={{ padding: "0" }}
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <div className="projectWrapper">
        {projects &&
          projects.map((project) => {
            return (
              <motion.li
                key={project.id}
                variants={item}
                style={{ listStyleType: "none" }}
              >
                <ProjectCard
                  key={project.id}
                  projectDetails={project}
                  loading={loading}
                  userVoteRecord={
                    auth ? userVotesRecords.filter((record) => Number(project.id) === Number(record.project)) : false
                  }
                
                />
              </motion.li>
            );
          })}
      {projects.length > 0 && <Stack className="pagination" spacing={1}>
        <div 
        style={{
          visibility: paginationPages === 1 ? "hidden" : "visible"
        }}
        className="paginationHolder">
          <Pagination 
          showFirstButton
          showLastButton
          sx={{justifyContent: "center"}} color='primary' 
            count={paginationPages} 
            size="large"
            page={currentPage}
            onChange={(event, value) => {
              window.scrollTo({
                top: 0,
              });
              setCurrentPage(value);
              }
            }
          />
          </div>
      </Stack>
      }
      {
        projects.length === 0 && 
        <Alert 
        sx={{margin: "20px",padding: "10px 20px", fontSize: "15px"}} severity="warning">
         Żaden z dodanych projektów nie wykorzystuje takiego stacku technologicznego.
       </Alert>
      }
      {
        error &&
        <Alert sx={{margin: "20px",padding: "10px 20px", fontSize: "15px"}} severity="warning"
        >
          Błąd strony.
        </Alert>
      }
      </div>
    </motion.ul>
  );
};
}

export default ProjectWrapper;
