import "./App.css";
import React, { useContext, useState, useEffect } from "react";
import { Login, Logout, Navigation } from "./components/exports";
import MainPage from "./pages/mainPage/MainPage";
import Footer from "./components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import SingleProjectPage from "./pages/singleProjectPage/SingleProjectPage";
import HowItWorksPage from "./pages/howItWorksPage/HowItWorksPage";
import { ScrollToTopButton } from "./components/scrollTopButton/ScrollTopButton";
import { ToggleFilterProvider } from "./contexts/ToggleFilterContext";
import { AlertContext } from "./contexts/AlertProvider";
import LoggedIn from "./components/authentication/LoggedIn";
import ErrorAlert from "./pages/errorPage/ErrorPage";
import CookieBanner from "./components/cookieBanner/CookieBanner";
import Notification from "./components/notification/Notification";
import ChangePassword from "./components/authentication/ChangePassword";
import PwdChanged from "./components/authentication/PwdChanged";

function App() {
  const { showAlert } = useContext(AlertContext);
  const [rotationAngle, setRotationAngle] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const newRotationAngle = scrollPosition/35 + 280;
    setRotationAngle(newRotationAngle);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <ToggleFilterProvider>
      <div className="App">
        <div className="decoration-vector"></div>
        <div className="decoration-vector" 
        style={{
          transform: `rotate(${rotationAngle}deg)`,
        }}
        ></div>
        <CookieBanner />
        <ScrollToTopButton />
        <Navigation />
        <div
          style={{ fontSize: "40px", marginTop: "90px" }}
          id="backend-test"
        ></div>
        {
          showAlert && 
          <Notification />
          }
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/logowanie" element={<Login />} />
          <Route path="/wylogowanie" element={<Logout />} />
          <Route path="/projekt/:id" element={<SingleProjectPage />} />
          <Route path="/jak-to-dziala" element={<HowItWorksPage />} />
          <Route path="/poprawne-logowanie" element={<LoggedIn />} />
          <Route path="/zmiana-hasla" element={<ChangePassword />} /> 
          <Route path="/poprawna-zmiana-hasla" element={<PwdChanged />} /> 
          <Route path="*" element={<ErrorAlert />} />
        </Routes>
        <Footer />
      </div>
    </ToggleFilterProvider>
  );
}

export default App;
