import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import work from "../../../assets/work.svg";
import development from "../../../assets/development.svg";
import experience from "../../../assets/experience.svg";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Box className="how-it-works">
        <div className="titlebar">
          <h1>Dlaczego?</h1>
        </div>
        <p>
          Aplikacja którą masz teraz przed swoimi oczyma została stworzona przez
          wyszkolonych programistów (kursantów) w ramach mentoringu prowadzonego
          przez{" "}
          <Link
            href="https://www.devs-mentoring.pl/"
            sx={{ textDecoration: "none" }}
          >
            Devs-Mentoring.pl
          </Link>
          . Stanowi ona jeden z mikroserwisów realizowanych w ramach CRM naszej
          firmy.{" "}
        </p>
        <p>
          Rozwijamy wiele in-house aplikacji i w ten sposób zapewniamy jeszcze
          efektywniejszy rozwój najbardziej ambitnym uczniom!
        </p>
        <Grid container justifyContent="space-around" gap={3}>
          <Grid item xs={5} sm={4} md={3} className="grid__container">
            <div>
              <img src={work} alt="" className="grid__img" />
              <h2 className="vertical-timeline-element-title">Praca w SCRUM</h2>
              <p className="grid__text">
                Tworzenie projektów utrzymane jest w metodyce SCRUM i
                maksymalnie zbliżone do profesjonalnego środowiska komercyjnego.
              </p>
            </div>
            <div className="keywords">
              {["#zarządzanie", "#wartości", "#zespół"].map((keyword) => {
                return (
                  <div className="keyword" key={keyword}>
                    {keyword.toUpperCase()}
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={5} sm={4} md={3} className="grid__container">
            <div>
              <img src={development} alt="" className="grid__img" />
              <h2 className="vertical-timeline-element-title">
                Rozwój kompetencji
              </h2>
              <p className="grid__text">
                Projekty rozwijają zarówno umiejętności twarde jak i miękkie.
              </p>
            </div>

            <div className="keywords">
              {["#progres", "#ewolucja", "#dynamizacja"].map((keyword) => {
                return (
                  <div className="keyword" key={keyword}>
                    {keyword.toUpperCase()}
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={5} sm={4} md={3} className="grid__container">
            <div>
              <img src={experience} alt="" className="grid__img" />
              <h2 className="vertical-timeline-element-title">
                Doświadczenie w CV
              </h2>
              <p className="grid__text">
                Umiejętności to nie wszystko, dlatego nasze wewnętrzne projekty
                mogą być cennym doświadczeniem w CV.{" "}
              </p>
            </div>
            <div className="keywords">
              {["#praktyka", "#projekty", "#współpraca"].map((keyword) => {
                return (
                  <div className="keyword" key={keyword}>
                    {keyword.toUpperCase()}
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
};

export default About;
