import { RotatingLines } from "react-loader-spinner";

import "./loadingComponent.css";

const LoadingComponent = () => {
  return (
    <div className="loading__container">
      <RotatingLines
        strokeColor="black"
        strokeWidth="5"
        animationDuration="1"
        width="40"
        visible={true}
      />
    </div>
  );
};

export default LoadingComponent;
