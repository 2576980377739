import React, {useState, useEffect, useContext} from "react";
import { Typography, Link } from "@mui/material";
import { FeedbackIcon, TechButtons, UpVoteIcon } from "../reusables/exports";
import AuthContext from "../../contexts/AuthProvider";
import CommentContext from "../../contexts/CommentProvider";
import { HiCodeBracketSquare } from 'react-icons/hi2'
import { PiMonitorPlayFill } from 'react-icons/pi'
import "./projectDetails.css";

const ProjectDetails = ({ projectDetails, userVoteRecord }) => {
  const {
    id,
    title,
    author,
    featured_image,
    comment_count,
    tags,
    up_votes,
    source_link,
    demo_link,
    description,
  } = projectDetails;
  const {auth} = useContext(AuthContext);
  const {newCommentsNumber, setNewCommentsNumber} = useContext(CommentContext);
  const [upVoteRecordId, setUpVoteRecordId] = useState(null);
  const [upVoteBoolean, setUpVoteBoolean] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [voteAmount, setVoteAmount] = useState(up_votes);
  const [sourceLink] = useState(source_link ? source_link : false);
  const [demoLink] = useState(demo_link ? demo_link : false);
  const forceRerender = (newValue) => {
    setUpVoteBoolean(newValue)
  }

    const handleAmountOfVotes = async (increment) => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);
    try{
      await new Promise(resolve=>setTimeout(resolve, 700));
      if (increment) {
        setVoteAmount(prevAmount => prevAmount + 1);
      } else if(increment === 0 || increment === null){
        setVoteAmount(1)
      } else {
        setVoteAmount(prevAmount => prevAmount <= 0 ? 0 : prevAmount - 1);
      }

    } catch(err){
      console.error(err)
    } finally{
      setIsUpdating(false)
    }

  }

  useEffect(()=>{
    setNewCommentsNumber(comment_count);
    //eslint-disable-next-line
  }, [])

  useEffect(()=> {
  if(auth && userVoteRecord) {
    const [objectUserVoteRecord] = userVoteRecord;
    if( objectUserVoteRecord !== undefined ) {
      setUpVoteRecordId(objectUserVoteRecord.id);
      setUpVoteBoolean(objectUserVoteRecord.vote);
    }
  }  
  },[userVoteRecord, auth]);

  useEffect(()=> {
    const handleScroll = () => {
      if(document.querySelector(".comments")){
        const commentSection = document.querySelector(".comments")
        window.scrollTo({
          top: commentSection.offsetTop,
          behavior: "smooth",
        });
      }
    }
    if(localStorage.getItem("scrollToComments")){
      setTimeout(handleScroll, 200);  
      localStorage.removeItem("scrollToComments")
    } else {
      localStorage.removeItem("scrollToComments")
    }
  }, []);

  return (
    <>
      <section key={id} className="project-details">
        <div className="details__container">
          <div className="details-wrapper">
            <Typography className="project__title" variant="h3" component="h1">
              {title}
            </Typography>
            <Typography className="project__author" variant="h6">
              Autor: {author}
            </Typography>
            <Typography className="project__code-link" variant="h6">
              <HiCodeBracketSquare style={{color: "#dbc112", fontSize: 20}}/> Kod:{" "}
              <Link
                sx={{transition: "200ms", textDecoration: "none", color: "unset", "&:hover": {textDecoration: "underline", color: "#DBC111"}}}
                disabled={ sourceLink ? false : true}
                href={sourceLink ? source_link.slice(0, 4) !== "http" ? `http://${source_link}` : source_link : null }
                target="_blank"
                rel="noopener"
              >
                {sourceLink ? source_link : null}          
              </Link>
                {!sourceLink ? "Brak linku." : null}
            </Typography>
            <Typography className="project__live-link" variant="h6">
              <PiMonitorPlayFill style={{color: "#dbc112", fontSize: 20}} /> Live:{" "}
              <Link
                sx={{transition: "200ms", textDecoration: "none", color: "unset", "&:hover": {textDecoration: "underline", color: "#DBC111"}}}
                href={demoLink ? demo_link.slice(0, 4) !== "http" ? `http://${demo_link}` : demo_link : null }
                target="_blank"
                rel="noopener"
              >
                {demoLink ? demo_link : null}
              </Link>
                {!demoLink ? "Brak linku." : null}
            </Typography>

            <div className="badges-wrapper">
                        <UpVoteIcon 
            up_votes={voteAmount} 
            project_id={id} 
            record_id={auth ? upVoteRecordId: null} 
            voteBoolean={auth ? upVoteBoolean : null}
            forcingRerender={forceRerender}
            handleAmountOfVotes={handleAmountOfVotes}
            isUpdating={isUpdating}
            setUpVoteRecordId={setUpVoteRecordId}
            setUpVoteBoolean={setUpVoteBoolean}
          />
              
              <FeedbackIcon comment_count={newCommentsNumber} />
            </div>
            <div className="tech-used">
              <Typography sx={{
                fontFamily: 'League Spartan, sans-serif',
                fontSize: "15px"
              }} variant="body2" component={"p"}>
                Tech used:{" "}
              </Typography>
              <TechButtons tags={tags} />
            </div>
          </div>
          <div className="image-wrapper">
            <img src={featured_image} alt="project's view" />
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }} className="description">
          <h1>Opis:</h1>
          {description}
        </div>
      </section>
    </>
  );
};

export default ProjectDetails;
