import axios from 'axios';
import { getCookie } from './getCookie';

export const postNewComment = async (projectId, commentText) => {
    try {
        const csrfToken = getCookie("csrftoken");
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/comment/`, {
            "project": projectId,
            "text": commentText
            },
            {
                withCredentials: "true",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                mode: "same-origin"
            }
        );
        return response;
    } catch(error) {
        console.error(`Post newComment error: ${error}`);
        return error;
    }
}

export const getUsersComments = async (projectId, userId) => {
    try {
        const csrfToken = getCookie("csrftoken");
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/comment/?author=${userId}&project=${projectId}`,
            {
                withCredentials: "true",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                mode: "same-origin"
            }
        );
        return response;
    } catch(error) {
        console.error(`Get usersComments error: ${error}`);
        return error;
    }
}

export const deleteComment = async (commentId) => {
    try {
        const csrfToken = getCookie("csrftoken");
        const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/comment/${commentId}`,
            {
                withCredentials: "true",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                mode: "same-origin"
            }
        );
        return response;
    } catch(error) {
        console.error(`Delete comment error: ${error}`);
        return error;
    }
}

export const patchComment = async (commentId, projectId, newText) => {
    
    try {
        const csrfToken = getCookie("csrftoken");
        const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/comment/${commentId}/`, {
            "project": projectId,
            "text": newText,
        },
        {
            withCredentials: "true",
            headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                mode: "same-origin"
        });
        return response;
    } catch(error) {
        console.error(`Patch comment error: ${error}`);
    }
}