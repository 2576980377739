import axios from 'axios';

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login/`, {
            "email": email,
            "password": password
        },
        {withCredentials: true}
        );
        return response;
    } catch(error) {
        console.error(`Login post error: ${error}`);
        throw new Error(error.response.status.toString())
    }
}