import React, { useState, useContext } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { changePassword } from '../../services/changePwd';
import { logout } from '../../services/logout';
import AuthContext from '../../contexts/AuthProvider';

const ChangePasswordForm = () => {
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
  });
  const {auth, setAuth} = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorMessage('');
    setSuccessMessage(false)
  };

  const handleClickShowPassword = (prop) => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function actLogout() {  
    if(auth){
      
      setAuth(false);
      localStorage.removeItem("userId")
      try{
        await logout();
      } catch(err){
        console.error(err);
      }
    } else {
      return
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      const response = await changePassword(values.currentPassword, values.newPassword);
      if(response.status === 200) {
        await actLogout()
        navigate("/poprawna-zmiana-hasla")
      }
    } catch(err){
      console.error(err);
      setErrorMessage("Coś poszło nie tak.");
    }
    const resetForm = () => {
      setValues({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        showCurrentPassword: false,
        showNewPassword: false,
      });
    };
    resetForm();
  };

    return (
      <form className='login' style={{marginTop: "50px"}} onSubmit={handleSubmit}>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          validate='true'
          autoComplete='off'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="current-password">Aktualne hasło:</InputLabel>
          <OutlinedInput
          id="current-password"
          type={values.showCurrentPassword ? 'text' : 'password'}
          value={values.currentPassword}
          onChange={handleChange('currentPassword')}
          label="Current Password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle current password visibility"
                onClick={() => handleClickShowPassword('showCurrentPassword')}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        </FormControl>
  
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="new-password">Nowe hasło:</InputLabel>
          <OutlinedInput
            id="new-password"
            type={values.showNewPassword ? 'text' : 'password'}
            value={values.newPassword}
            onChange={handleChange('newPassword')}
            label="New Password"
            endAdornment={
              <InputAdornment position="end">
              <IconButton
                aria-label="toggle new password visibility"
                onClick={() => handleClickShowPassword('showNewPassword')}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }
          />
        </FormControl>
  
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="confirm-password">Powtórz nowe hasło:</InputLabel>
          <OutlinedInput
            id="confirm-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            label="Confirm Password"
          />
          {values.newPassword !== values.confirmPassword && (
            <FormHelperText error>Hasła nie pasują do siebie</FormHelperText>
          )}
          {values.newPassword.length < 8 && values.newPassword.length > 0 && (
            <FormHelperText error>Hasło musi mieć &gt;= 8 znaków</FormHelperText>
          )}
        {errorMessage && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}
          {
            successMessage && (
              <FormHelperText sx={{color: "green", fontSize: "14px"}}>Udana zmiana hasła.</FormHelperText>
            )
          }
        </FormControl>

  
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{marginTop: "25px"}}
          disabled={
            !values.currentPassword ||
            !values.newPassword ||
            values.newPassword !== values.confirmPassword
          }
        >
          Zmień hasło
        </Button>
                  </div>
        </Box>
      </form>
    );
  }

export default ChangePasswordForm;
