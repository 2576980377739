import React, { useContext, useState, useEffect } from 'react';
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AuthContext from '../../../contexts/AuthProvider';
import { patchUpVote, postUpVote } from '../../../services/upVote';
import { AlertContext } from '../../../contexts/AlertProvider';

const UpVoteIcon = ({up_votes, project_id, record_id, voteBoolean, forcingRerender, handleAmountOfVotes, isUpdating, setUpVoteRecordId, setUpVoteBoolean}) => {
  const {auth} = useContext(AuthContext);
  const { showAlertComponent } = useContext(AlertContext);
  const [userStateOfVote, setUserStateOfVote] = useState("loggedOut");
  const [badgeColor, setBadgeColor] = useState(voteBoolean ? "red" : "grey");
  useEffect(()=>{
    if(auth){
      setBadgeColor(voteBoolean ? "red" : "grey")
      record_id === null ?
        setUserStateOfVote("logged, no vote")
        :
        setUserStateOfVote("logged, voted")
    }

  },[auth, record_id, voteBoolean])

  async function createVote() {
    try{
      const response = await postUpVote(project_id);
      const newVote = await response.data.vote;
      const newRecordValues = await response.data;
      forcingRerender(newVote);
      setUpVoteRecordId(newRecordValues.id);
      setUpVoteBoolean(newRecordValues.vote);
      return response;
    } catch(err) {
      console.error(err)
    }
  }
  async function changeVote(){ 
    try{
      const response = await patchUpVote(record_id, project_id, voteBoolean);
      const newVote = await response.data.vote;
      forcingRerender(newVote);
      return response;
    } catch(err){
      console.error(err);
    }
  }

  const handleIncrement = () => {
    handleAmountOfVotes(true);
  }

  const handleDecrement = () => {
    handleAmountOfVotes(false);
  }


  return (
    <Badge 
    sx={{transform: "scale(0.85)"}}
    badgeContent={up_votes} 
    color={"primary"} 
    max={999}>
            <IconButton
              disabled={isUpdating}
              onClick={() => {
                if(auth){
                  if(userStateOfVote === "logged, no vote") {
                    createVote();
                    handleIncrement();
                    setUserStateOfVote("logged, voted")
                  } else if(userStateOfVote === "logged, voted") {
                    changeVote() && badgeColor === "red" ?
                    handleDecrement()
                    :
                    handleIncrement()
                  } else if(userStateOfVote === "loggedOut") {
                    showAlertComponent("Zaloguj się, aby polubić projekt.");
                  }
                } else {
                  showAlertComponent("Zaloguj się, aby polubić projekt.");
                }
              }}
              aria-label="add to favorites"
            >
              <FavoriteIcon sx={{color: badgeColor}} />
            </IconButton>
          </Badge>
  )
}

export default UpVoteIcon