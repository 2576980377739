import axios from 'axios';
import { getCookie } from './getCookie';

export const changePassword = async (currentPassword, newPassword) => {
    const csrfToken = getCookie('csrftoken');
    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/auth/change_password/`, {
            "current_password": currentPassword,
            "new_password": newPassword
        },
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken
            }
        }
        );
        return response;
    } catch(error) {
        console.error(`Change password patch method error: ${error}`);
        throw new Error(error.response.status.toString())
    }
}